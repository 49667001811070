import request from "@/utils/request";
export function getlike(params) {
  return request({
    url: `/api/ipification/getURL/${params.state}/${params.channel}`,
    method: "get",
  });
}
export function getInfo(state) {
  return request({
    url: `/api/ipification/getInfo/${state}`,
    method: "post",
  });
}
export function gettoken(data) {
  return request({
    url: `/realms/ipification/protocol/openid-connect/token`,
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  });
}
export function getuserinfo(data) {
  return request({
    url: `/realms/ipification/protocol/openid-connect/userinfo`,
    method: "get",
    headers: {
      Authorization: `Bearer ${data}`,
    },
  });
}
export function getcoverage(data) {
  return request({
    url: `/realms/ipification/coverage/`,
    method: "get",
    params:data
  });
}

