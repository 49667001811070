<template>
  <div class="hello">
    <div style="height:160px">
      <!-- <img
        src="../assets/img/log.png"
        alt=""
        style="width: 100px; margin-top: 60px"
      /> -->
    </div>

    <div class="maintop">
      <p style="color: #101010; font-size: 14px">登录体验完整功能</p>
      <van-button
        round
        color="#EB3B4B 100%"
        @click="go(1)"
        class="bg"
        style="width: 100%; height: 50px; font-size: 16px"
        ><i class="iconfont icon-shouji_o" style="font-size: 26px"></i
        >用手机号码登录 / 注册</van-button
      >
      <van-button
        round
        color="#19D840 100%"
        class="bg"
        style="width: 100%; margin-top: 20px; height: 50px; font-size: 16px"
        @click="open('wa')"
        ><i class="iconfont icon-wa" style="font-size: 30px"></i
        >用Whastsapp登录</van-button
      >
      <van-button
        round
        color="#7C6ECE 100%"
        class="bg"
        @click="open('viber')"
        style="width: 100%; margin-top: 20px; height: 50px; font-size: 16px"
        ><i class="iconfont icon-viber" style="font-size: 30px"></i
        >用Viber登录</van-button
      >
      <van-button
        round
        color="#61A8DE 100%"
        class="bg"
        @click="open('telegram')"
        style="width: 100%; margin-top: 20px; height: 50px; font-size: 16px"
        ><i class="iconfont icon-telegram" style="font-size: 30px"></i
        >用Telegram登录</van-button
      >
    </div>
    <div class="foot">
      <van-divider
        :hairline="false"
        :style="{
          color: '#000',
          borderColor: '#ccc',
          padding: '0 16px',
          width: '222px',
          margin: '0 auto',
        }"
        >或</van-divider
      >

      <ul>
        <li><img src="../assets/img/google.png" alt="" /></li>
        <li><img src="../assets/img/tw.png" alt="" /></li>
        <li><img src="../assets/img/fb.png" alt="" /></li>
      </ul>
      <p
        style="
          padding-top: 15px;
          font-size: 12px;
          color: rgba(140, 136, 136, 100);
        "
      >
        登录即表示您同意我们的<span>用户协议</span>和<span>隐私条款</span>
      </p>
    </div>
  </div>
</template>

<script>
import { getlike, getInfo } from "@/api/api";
import { getRandomString } from "../utils/utils.js";

export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "44444",
      state: "a70Q6N8oGVVfVNMV",
    };
  },
  methods: {
    go(type) {
      if (type == 1) {
        let Toast = this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "loading...",
        });
        setTimeout(() => {
          Toast.clear();
          this.$router.push("/about");
        }, 1000);
      }
    },
    open(channel) {
      let Toast = this.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: "loading...",
      });
      this.state = getRandomString(16);
      let data = { state: this.state, channel: channel };
      getlike(data)
        .then((response) => {
          console.log(response);
          let url = response.data;
          window.location.href = url;
          // window.open(
          //   url,
          //   "_blank",
          //   "scrollbars=yes,resizable=1,modal=false,alwaysRaised=yes"
          // );
          // window.open(url);
          this.getuser(Toast);
        })
        .catch((error) => {
          Toast.clear();
          console.log(error);
        });
    },
    getuser(Toast) {
      let THIS = this;
      let i = 0;
      let timer = setInterval(() => {
        getInfo(THIS.state)
          .then((response) => {
            i++;
            if (i == 30) {
              clearInterval(timer);
              this.$toast("操作超时请重试");
            }

            if (response.data) {
              clearInterval(timer);
              this.$router.push({
                path: "/Success",
                query: { tel: response.data.phone_number },
              });
              Toast.clear();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 1000);
      // setTimeout(() => {
      //   this.$toast("操作超时请重试");
      //   clearInterval(timer);
      // }, 10000);
    },
  },
  mounted() {
    // this.getuser();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.hello {
  padding: 10px;
  max-width: 390px;
  margin: 0 auto;
}
.maintop {
  height: 300px;
  width: 340px;
  margin: 90px auto 30px;
}
.foot {
  width: calc(100% - 20px);
  max-width: 390px;
  margin: 0px auto;
}
.foot ul li img {
  width: 30px;
  height: 30px;
}
.foot span {
  color: #000;
  font-weight: bold;
}
.foot ul {
  margin-top: 10px;
}
.bg {
  position: relative;
}

.bg i {
  position: absolute;
  left: 20px;
  top: 6px;
  bottom: 0;
  margin: auto;
  width: 50px;
}
</style>
