import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import Vant from "vant";
import "vant/lib/index.css";
import "./assets/font/iconfont.css";
import VConsole from "vconsole";

// let vConsole = new VConsole();
Vue.use(Vant);
Vue.config.productionTip = false;
// Vue.use(vConsole);
/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
